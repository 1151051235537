/**
* This file is part of AudioBot Project
* Copyright (C) 2024 W3villa Technologies
* 
* This program is free software: you can redistribute it and/or modify
* it under the terms of the GNU Affero General Public License as
* published by the Free Software Foundation, either version 3 of the License, or
* (at your option) any later version.
* 
* This program is distributed in the hope that it will be useful,
* but WITHOUT ANY WARRANTY; without even the implied warranty of
* MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
* GNU Affero General Public License for more details.
* 
* You should have received a copy of the GNU Affero General Public License
* along with this program. If not, see <https://www.gnu.org/licenses/agpl-3.0.txt>.
*/

import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL;
export const wsBaseUrl = process.env.REACT_APP_WS_URL;


export const startConversation = ({ body }) => {
    const url = `${baseUrl}/api/conversations/start_conversation`

    const headers = {
        'Content-Type': 'multipart/form-data',
    };

    return axios.post(url, body, { headers: headers });
};


export const conversation = ({ body, sessionId }) => {
    const url = `${baseUrl}/api/conversations/conversation`

    const headers = {
        'Content-Type': 'multipart/form-data',
        'sessionId': sessionId
    };

    return axios.post(url, body, { headers: headers });
};

export const getAudio = () => {
    const url = `${baseUrl}/api/conversations/get_audio`

    return axios.get(url, {
        responseType: 'blob',
    });    
};


export const startTextConversation = ({ body }) => {
    const url = `${baseUrl}/api/conversations/start_text_conversation`

    const headers = {
        'Content-Type': "application/json",
    };

    body = JSON.stringify(body);

    return axios.post(url, body, { headers: headers });
};


export const textConversation = ({ body, sessionId }) => {
    const url = `${baseUrl}/api/conversations/text_conversation`

    const headers = {
        'Content-Type': "application/json",
        'sessionId': sessionId
    };

    body = JSON.stringify(body);

    return axios.post(url, body, { headers: headers });
};

export const createContact = ({ body }) => {
    const url = `${baseUrl}/api/conversations/contact`

    const headers = {
        'Content-Type': "application/json"
    };

    body = JSON.stringify(body);

    return axios.post(url, body, { headers: headers });
};

export const _generateEmailOtp = ({ body }) => {
    const url = `${baseUrl}/api/emails/generate_email_otp`

    const headers = {
        'Content-Type': "application/json"
    };

    body = JSON.stringify(body);

    return axios.post(url, body, { headers: headers });
};

export const _verifyEmailOtp = ({ body }) => {
    const url = `${baseUrl}/api/emails/verify_email_otp`

    const headers = {
        'Content-Type': "application/json"
    };

    body = JSON.stringify(body);

    return axios.post(url, body, { headers: headers });
};

export const documentConversation = ({ formData, sessionId }) => {
    const url = `${baseUrl}/api/conversations/document_conversation`

    const headers = {
        'Content-Type': 'multipart/form-data'
    };

    if (sessionId) {
        headers["sessionId"] = sessionId
    }

    return axios.post(url, formData, { headers: headers });
};

export const _createCallDetail = ({ body }) => {
    const url = `${baseUrl}/api/calls/send_otp`

    const headers = {
        'Content-Type': "application/json"
    };

    body = JSON.stringify(body);

    return axios.post(url, body, { headers: headers });
};

export const _verifyPhoneOtp = ({ body }) => {
    const url = `${baseUrl}/api/calls/verify_otp`

    const headers = {
        'Content-Type': "application/json"
    };

    body = JSON.stringify(body);

    return axios.post(url, body, { headers: headers });
};


export const _setLlmAPI = ({ body }) => {
    const url = `${baseUrl}/api/config/set_llm`

    const headers = {
        'Content-Type': "application/json"
    };

    body = JSON.stringify(body);

    return axios.post(url, body, { headers: headers });
};


export const _getLlmAPI = () => {
    const url = `${baseUrl}/api/config/get_llm`

    const headers = {
        'Content-Type': "application/json"
    };

    return axios.get(url, { headers: headers });
};

export const _scrapWebsite = ({ body }) => {
    const url = `${baseUrl}/api/conversations/scrap_website`

    const headers = {
        'Content-Type': "application/json"
    };

    body = JSON.stringify(body);

    return axios.post(url, body, { headers: headers });
};

export const _translate = (payload) => {
    const url = `${baseUrl}/api/conversations/translate`

    const headers = {
        'Content-Type': "application/json",
    };

    const body = JSON.stringify(payload);

    return axios.post(url, body, { headers: headers });
};
