import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

function Instruction() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <Button className='interpreter-btn' style={{ opacity: '0.6', color: '#000000' }} onClick={handleShow}>
                Instruction
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Instruction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Select your preferred language to speak and hear translations in real-time. The other user should do the same on their own system by opening the Remote Interpreter page.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Instruction