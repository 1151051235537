import React, { useState, useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';

const LanguageModal = ({ isModalOpen, handleClose, languages, handleLanguageSelect }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleModalClose = () => {
        setSearchTerm('');
        handleClose();
    };

    const filteredLanguages = useMemo(
        () =>
            languages.filter((lang) =>
                lang.toLowerCase().includes(searchTerm.toLowerCase())
            ),
        [languages, searchTerm]
    );

    return (
        <Modal show={isModalOpen} onHide={handleModalClose} className="interpret-modal">
            <Modal.Header closeButton className="border-0 pb-0">
                <Modal.Title>Select a language</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormControl
                    type="text"
                    placeholder="Search language"
                    className="mb-3"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="d-flex flex-column align-items-center">
                    {filteredLanguages.map((lang) => (
                        <Button
                            key={lang}
                            variant="outline-primary border-radius-50 font-14"
                            className="mb-2 language-button"
                            onClick={() => handleLanguageSelect(lang)}
                        >
                            {lang}
                        </Button>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LanguageModal;