import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faVolumeHigh, faPauseCircle } from '@fortawesome/free-solid-svg-icons';
import IntrepreterLangInput from './InterpreterLangInput';
import { languageCodes } from '../../constant';

// Import the API functions
import { startTextConversation, textConversation } from '../../api/Api';

function Interpreter() {
    // State variables
    const [fromLanguage, setFromLanguage] = useState("English");
    const [toLanguage, setToLanguage] = useState("English");
    const [texts, setTexts] = useState({ from: "", to: "" });
    const [displayedTexts, setDisplayedTexts] = useState({ from: "", to: "" });
    const [listening, setListening] = useState({ from: false, to: false });
    const [isSpeaking, setIsSpeaking] = useState({ from: false, to: false });
    const [currentUtterances, setCurrentUtterances] = useState({ from: null, to: null });
    const listeningRefs = useRef({ from: false, to: false });

    const recognitionRefs = useRef({ from: null, to: null });
    const sessionIdRef = useRef(null);

    useEffect(() => {
        sessionIdRef.current = null;
        recognitionRefs.current = { from: null, to: null };
    }, [fromLanguage, toLanguage]);

    const initRecognition = (direction) => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!SpeechRecognition) {
            console.error('Browser does not support speech recognition.');
            return null;
        }

        const recognition = new SpeechRecognition();
        recognition.lang = languageCodes[direction === 'from' ? fromLanguage : toLanguage] || 'en-US';
        recognition.interimResults = false;
        recognition.continuous = false;

        recognition.onstart = () => {
            setListening(prev => ({ ...prev, [direction]: true }));
            listeningRefs.current[direction] = true;
        };

        recognition.onend = () => {
            setListening(prev => ({ ...prev, [direction]: false }));
            listeningRefs.current[direction] = false;
        };

        recognition.onresult = (event) => {
            const transcript = Array.from(event.results)
                .map(result => result[0].transcript)
                .join('');
            setTexts(prev => ({ ...prev, [direction]: transcript }));
            handleApiCall(transcript, direction);
        };

        recognition.onerror = (event) => {
            console.error('Speech recognition error detected: ' + event.error);
        };

        return recognition;
    };

    const handleMicClick = (direction) => {
        if (!recognitionRefs.current[direction]) {
            recognitionRefs.current[direction] = initRecognition(direction);
        }

        if (!listeningRefs.current[direction]) {
            recognitionRefs.current[direction].start();
        } else {
            recognitionRefs.current[direction].stop();
        }
    };

    useEffect(() => {
        // Update the recognition language when languages change
        if (recognitionRefs.current.from) {
            recognitionRefs.current.from.lang = languageCodes[fromLanguage] || 'en-US';
        }
        if (recognitionRefs.current.to) {
            recognitionRefs.current.to.lang = languageCodes[toLanguage] || 'en-US';
        }
    }, [fromLanguage, toLanguage]);

    useEffect(() => {
        // Cleanup function
        return () => {
            if (recognitionRefs.current.from) {
                recognitionRefs.current.from.abort();
            }
            if (recognitionRefs.current.to) {
                recognitionRefs.current.to.abort();
            }
            window.speechSynthesis.cancel();
        };
    }, []);

    const handleApiCall = async (userText, direction) => {
        const agent = 'Interpreter';
        const language_translate = { from: fromLanguage, to: toLanguage };

        try {
            let response;
            if (!sessionIdRef.current) {
                response = await startTextConversation({
                    body: { user_text: userText, agent, language_translate },
                });
                sessionIdRef.current = response.data.sessionId;
            } else {
                response = await textConversation({
                    body: { user_text: userText },
                    sessionId: sessionIdRef.current,
                });
            }
            handleApiResponse(response, direction);
        } catch (error) {
            console.error('Error calling API:', error);
        }
    };

    const handleApiResponse = (response, direction) => {
        try {
            const aiResponse = response.data.audio_text.ai_response;
            const oppositeDirection = direction === 'from' ? 'to' : 'from';

            setTexts(prev => ({ ...prev, [oppositeDirection]: aiResponse }));
            handleSpeechSynthesis(aiResponse, oppositeDirection);
        } catch (error) {
            console.error('Error processing API response:', error);
        }
    };

    const handleSpeechSynthesis = (text, direction) => {
        const oppositeDirection = direction === 'from' ? 'to' : 'from';

        if (currentUtterances[oppositeDirection]) {
            window.speechSynthesis.cancel();
            setIsSpeaking(prev => ({ ...prev, [oppositeDirection]: false }));
            setCurrentUtterances(prev => ({ ...prev, [oppositeDirection]: null }));
        }

        const utterance = new SpeechSynthesisUtterance(text);
        utterance.lang = languageCodes[direction === 'from' ? fromLanguage : toLanguage] || 'en-US';

        utterance.onstart = () => {
            setIsSpeaking(prev => ({ ...prev, [direction]: true }));
        };

        utterance.onend = () => {
            setIsSpeaking(prev => ({ ...prev, [direction]: false }));
            setCurrentUtterances(prev => ({ ...prev, [direction]: null }));
            setTimeout(() => {
                handleMicClick(direction);
            }, 2000);
        };

        utterance.onerror = (event) => {
            console.error('Speech synthesis error: ' + event.error);
            setIsSpeaking(prev => ({ ...prev, [direction]: false }));
            setCurrentUtterances(prev => ({ ...prev, [direction]: null }));
        };

        setCurrentUtterances(prev => ({ ...prev, [direction]: utterance }));
        window.speechSynthesis.speak(utterance);
    };

    const handleVolumeClick = (direction) => {
        if (isSpeaking[direction]) {
            window.speechSynthesis.pause();
            setIsSpeaking(prev => ({ ...prev, [direction]: false }));
        } else if (currentUtterances[direction]) {
            window.speechSynthesis.resume();
            setIsSpeaking(prev => ({ ...prev, [direction]: true }));
        } else if (texts[direction]) {
            handleSpeechSynthesis(texts[direction], direction);
        }
    };

    useEffect(() => {
        let intervalFrom;
        if (texts.from) {
            setDisplayedTexts(prev => ({ ...prev, from: "" }));
            let index = 0;
            intervalFrom = setInterval(() => {
                if (index <= texts.from.length) {
                    setDisplayedTexts(prev => ({ ...prev, from: texts.from.slice(0, index) }));
                    index++;
                } else {
                    clearInterval(intervalFrom);
                }
            }, 50);
        }
        return () => clearInterval(intervalFrom);
    }, [texts.from]);

    useEffect(() => {
        let intervalTo;
        if (texts.to) {
            setDisplayedTexts(prev => ({ ...prev, to: "" }));
            let index = 0;
            intervalTo = setInterval(() => {
                if (index <= texts.to.length) {
                    setDisplayedTexts(prev => ({ ...prev, to: texts.to.slice(0, index) }));
                    index++;
                } else {
                    clearInterval(intervalTo);
                }
            }, 50);
        }
        return () => clearInterval(intervalTo);
    }, [texts.to]);

    return (
        <div className='home-wrap'>
            <div className='flipContent'>
                {['from', 'to'].map((direction) => (
                    <div key={direction} className={`interpreter interpreter-${direction === 'from' ? 'top' : 'bottom'}`}>
                        <div className='interpreter-text-wrap'>{displayedTexts[direction]}</div>
                        <div className="language-section">
                            <FontAwesomeIcon
                                icon={isSpeaking[direction] ? faPauseCircle : faVolumeHigh}
                                className={`icon-volume ${isSpeaking[direction] ? 'blinking' : ''}`}
                                onClick={() => handleVolumeClick(direction)}
                            />
                            <IntrepreterLangInput
                                selectedLanguage={direction === 'from' ? fromLanguage : toLanguage}
                                onLanguageSelect={direction === 'from' ? setFromLanguage : setToLanguage}
                                disabled={listening[direction]}
                            />
                            <FontAwesomeIcon
                                icon={faMicrophone}
                                className={`icon-mic ${listening[direction] ? 'active' : ''}`}
                                onClick={() => handleMicClick(direction)}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Interpreter;