import React, { useEffect, useRef } from 'react';
import './RemoteChatBox.scss';
import userBlue from "../../assets/images/user_blue.png";

const RemoteChatBox = ({messages}) => {
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div className="chat-container">
      <div className="chat-messages">
        {messages.map((message) => (
          <div key={message.id} className="message">
            <div className="user-info">
              <div className="avatar">
                <img src={userBlue} alt="User" />
              </div>
              <div className="user-details">
                <h6 className="name">{message.name}</h6>
              </div>
              <span className="timestamp">{message.timestamp}</span>
            </div>
            <div className="message-content">
              <p>{message.content}</p>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default RemoteChatBox;