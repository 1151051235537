/**
* This file is part of AudioBot Project
* Copyright (C) 2024 W3villa Technologies
* 
* This program is free software: you can redistribute it and/or modify
* it under the terms of the GNU Affero General Public License as
* published by the Free Software Foundation, either version 3 of the License, or
* (at your option) any later version.
* 
* This program is distributed in the hope that it will be useful,
* but WITHOUT ANY WARRANTY; without even the implied warranty of
* MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
* GNU Affero General Public License for more details.
* 
* You should have received a copy of the GNU Affero General Public License
* along with this program. If not, see <https://www.gnu.org/licenses/agpl-3.0.txt>.
*/

import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import Home from "./components/Home/Home";
import ContactUs from "./components/Contact/ContactUs";
import ContactLayout from "./components/Contact/ContactLayout";
import ConfirmOtp from "./components/Contact/ConfirmOtp";
import ThankYou from "./components/Shared/ThankYou";
import CallConversation from "./components/Home/CallConversation";

const AppRoutes = () => {
    const [otpGenerated, setOtpGenerated] = useState(false);
    const [contactSubmitted, setContactSubmitted] = useState(false);
    const [call, setCall] = useState(false)
    const contactContent = "Your request has been submitted for the review. We will contact you for any further information."

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home setCall={setCall} />} />
                <Route element={<ContactLayout />}>
                    {call ? (<Route path="/conversation" element={<CallConversation />} />
                    ) : (<Route path="/conversation" element={<Navigate to="/" replace />} />)}
                    
                    <Route path="/contact" element={<ContactUs setOtpGenerated={setOtpGenerated} />} />
                    {otpGenerated ? (
                        <Route path="/verify-otp" element={<ConfirmOtp setContactSubmitted={setContactSubmitted} />} />
                    ) : (
                        <Route path="/verify-otp" element={<Navigate to="/" replace />} />
                    )}
                    {contactSubmitted ? (
                        <Route path="/thank-you" element={<ThankYou content={contactContent} />} />
                    ) : (
                        <Route path="/thank-you" element={<Navigate to="/" replace />} />
                    )}
                </Route>
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
    );
}

export default AppRoutes;
