import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faVolumeHigh, faPauseCircle } from '@fortawesome/free-solid-svg-icons';
import InterpreterLangInput from '../Home/InterpreterLangInput';
import { languageCodes } from '../../constant';
import { Modal, Button } from 'react-bootstrap';
import RemoteChatBox from './RemoteChatBox';
import { _translate, wsBaseUrl } from '../../api/Api';
import user from "../../assets/images/user_blue.png";

function RemoteInterpreter() {
    // State variables
    const [language, setLanguage] = useState('English');
    const [displayedText, setDisplayedText] = useState('');
    const [listening, setListening] = useState(false);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const [userName, setUserName] = useState('');
    const [receivedName, setReceivedName] = useState('');
    const [messages, setMessages] = useState([]);
    // Refs
    const languageRef = useRef(language);
    const recognitionRef = useRef(null);
    const wsRef = useRef(null);
    const currentUtteranceRef = useRef(null);
    const listeningRef = useRef(false);

    // Update languageRef whenever language state changes
    useEffect(() => {
        languageRef.current = language;
    }, [language]);

    // WebSocket connection
    useEffect(() => {
        const ws = new WebSocket(`${wsBaseUrl}/interpret/interpreter`);

        ws.onopen = () => {
            console.log('WebSocket connection opened');
        };

        ws.onmessage = (event) => {
            const receivedMessage = event.data;
            handleApiCall(receivedMessage);
        };

        ws.onclose = () => {
            console.log('WebSocket connection closed');
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        wsRef.current = ws;

        return () => {
            if (wsRef.current) {
                wsRef.current.close();
                wsRef.current = null;
            }
        };
    }, []);

    // Initialize Speech Recognition
    const initRecognition = useCallback(() => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!SpeechRecognition) {
            console.error('Browser does not support speech recognition.');
            return null;
        }

        const recognition = new SpeechRecognition();
        recognition.lang = languageCodes[languageRef.current] || 'en-US';
        recognition.interimResults = false;
        recognition.continuous = false;

        recognition.onstart = () => {
            setListening(true);
            listeningRef.current = true;
        };

        recognition.onend = () => {
            setListening(false);
            listeningRef.current = false;
        };

        recognition.onresult = (event) => {
            const transcript = Array.from(event.results)
                .map(result => result[0].transcript)
                .join('');
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                wsRef.current.send(`${userName}: ${transcript}`);
            } else {
                console.error('WebSocket is not connected.');
            }
        };

        recognition.onerror = (event) => {
            console.error('Speech recognition error detected: ' + event.error);
        };

        return recognition;
    }, [userName]);

    // Handle microphone click
    const handleMicClick = () => {
        if (!recognitionRef.current) {
            recognitionRef.current = initRecognition();
        }

        if (recognitionRef.current) {
            if (!listeningRef.current) {
                recognitionRef.current.start();
            } else {
                recognitionRef.current.stop();
            }
        }
    };

    // Handle language change
    useEffect(() => {
        if (recognitionRef.current) {
            recognitionRef.current.abort();
            recognitionRef.current = initRecognition();
        }
    }, [language, initRecognition]);

    // Cleanup on component unmount
    useEffect(() => {
        return () => {
            if (recognitionRef.current) {
                recognitionRef.current.abort();
                recognitionRef.current = null;
            }
            window.speechSynthesis.cancel();
            if (wsRef.current) {
                wsRef.current.close();
                wsRef.current = null;
            }
        };
    }, []);

    // Handle API call to translate
    const handleApiCall = useCallback(async (userText) => {
        // Split the received message to extract name and message
        const [name, message] = userText.split(': ');
        setReceivedName("Message from " + name);
        console.log("received name", name)
        if (message) {
            // Process the message part
            try {
                const response = await _translate({
                    text: message,
                    language: languageRef.current,
                });
                handleApiResponse(name, response);
            } catch (error) {
                console.error('Error calling _translate API:', error);
            }
        }
    }, []);

    function formatTimestamp() {
        const now = new Date();
        const timeString = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

        const today = new Date().toLocaleDateString();
        const dateString = now.toLocaleDateString();

        return dateString === today ? `today ${timeString}` : `${dateString} ${timeString}`;
    }

    // Handle API response
    const handleApiResponse = (name, response) => {
        try {
            const translatedText = response.data.translated_text;
            if (translatedText) {
                setDisplayedText('');
                animateTextDisplay(translatedText);
                setMessages(prevMessages => [
                    ...prevMessages,
                    { id: prevMessages.length + 1, name, content: translatedText, timestamp: formatTimestamp() }
                ]);
                handleSpeechSynthesis(translatedText);
            }
        } catch (error) {
            console.error('Error processing API response:', error);
        }
    };

    // Animate text display
    const animateTextDisplay = (text) => {
        let index = 0;
        const interval = setInterval(() => {
            if (index <= text.length) {
                setDisplayedText(text.slice(0, index));
                index++;
            } else {
                clearInterval(interval);
            }
        }, 50);
    };

    // Handle speech synthesis
    const handleSpeechSynthesis = (synthesisText) => {
        if (currentUtteranceRef.current) {
            window.speechSynthesis.cancel();
            setIsSpeaking(false);
            currentUtteranceRef.current = null;
        }

        const utterance = new SpeechSynthesisUtterance(synthesisText);
        utterance.lang = languageCodes[languageRef.current] || 'en-US';

        utterance.onstart = () => {
            setIsSpeaking(true);
        };

        utterance.onend = () => {
            setIsSpeaking(false);
            currentUtteranceRef.current = null;
            setTimeout(() => {
                handleMicClick();
            }, 1000);
        };

        utterance.onerror = (event) => {
            if (event.error === 'interrupted') {
                console.warn('Speech synthesis was interrupted due to language change.');
            } else {
                console.error('Speech synthesis error: ' + event.error);
            }
            setIsSpeaking(false);
            currentUtteranceRef.current = null;
        };

        currentUtteranceRef.current = utterance;
        window.speechSynthesis.speak(utterance);
    };

    // Handle volume icon click
    const handleVolumeClick = () => {
        if (isSpeaking) {
            window.speechSynthesis.pause();
            setIsSpeaking(false);
        } else if (currentUtteranceRef.current) {
            window.speechSynthesis.resume();
            setIsSpeaking(true);
        }
    };

    // Show modal after 2 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowModal(true);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    // Handle name submission
    const handleNameSubmit = () => {
        if (userName.trim()) {
            setShowModal(false);
        }
    };

    return (
        <div className='home-wrap'>
            <Modal show={showModal} onHide={() => { }} centered>
                <Modal.Header>
                    <Modal.Title>Enter Your Name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        type="text"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        placeholder="Your Name"
                        className='remote-name'
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleNameSubmit} disabled={!userName.trim()}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className='flipContent'>
                <div className='interpreter' style={{ height: '100%', position: 'relative' }}>
                    <div className='interpreter-title'>
                        <div className='interpreter-title-text'>
                            <div className='avatar'>
                                <img src={user} alt="User" />
                            </div>
                            <div>
                                <div>{userName || 'Guest'}</div>
                                <div className={`interpreter-${listening ? 'listening blinking' : 'active'}`}>{listening ? 'Listening...' : 'Active'}</div>
                            </div>
                        </div>
                    </div>
                    <RemoteChatBox messages={messages} />
                    <div className="language-section">
                        <FontAwesomeIcon
                            icon={isSpeaking ? faPauseCircle : faVolumeHigh}
                            className={`icon-volume ${isSpeaking ? 'blinking' : ''}`}
                            onClick={handleVolumeClick}
                        />
                        <InterpreterLangInput
                            selectedLanguage={language}
                            onLanguageSelect={setLanguage}
                            disabled={listening}
                        />
                        <FontAwesomeIcon
                            icon={faMicrophone}
                            className={`icon-mic ${listening ? 'active' : ''}`}
                            onClick={handleMicClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RemoteInterpreter;