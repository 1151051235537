import React, { useState } from 'react';
import LanguageModal from './LanguageModal';
import { languageNames } from '../../constant';

function InterpreterLangInput({ selectedLanguage, onLanguageSelect, disabled }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleLanguageSelect = (language) => {
        onLanguageSelect(language);
        setIsModalOpen(false);
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="interpreter-lang-input">
            <input 
                type="text" 
                value={selectedLanguage} 
                readOnly 
                onClick={!disabled ? () => setIsModalOpen(true) : null} // Disable onClick if disabled
            />
            <LanguageModal 
                isModalOpen={isModalOpen} 
                handleClose={handleClose} 
                languages={languageNames} 
                handleLanguageSelect={handleLanguageSelect} 
            />
        </div>
    );
}

export default InterpreterLangInput;