/**
* This file is part of AudioBot Project
* Copyright (C) 2024 W3villa Technologies
* 
* This program is free software: you can redistribute it and/or modify
* it under the terms of the GNU Affero General Public License as
* published by the Free Software Foundation, either version 3 of the License, or
* (at your option) any later version.
* 
* This program is distributed in the hope that it will be useful,
* but WITHOUT ANY WARRANTY; without even the implied warranty of
* MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
* GNU Affero General Public License for more details.
* 
* You should have received a copy of the GNU Affero General Public License
* along with this program. If not, see <https://www.gnu.org/licenses/agpl-3.0.txt>.
*/

import teacher from "../src/assets/images/teacher.png"
import ecommerce from "../src/assets/images/ecommerce.png"
import pizza from "../src/assets/images/pizza.svg"
import companyIcon from "../src/assets/images/skyline.png"
import translatorIcon from "../src/assets/images/translator.png"

export const agentLogo = {
    "Aria-W3villa-AI-Agent": companyIcon,
    "school-teacher": teacher,
    "e-commerce": ecommerce,
    "pizzaHome": pizza,
    "Translator":translatorIcon,
}

export const agentName = {
    "Aria-W3villa-AI-AgentWeb": "Aria-W3villa-AI-Agent",
    "Aria-W3villa-AI-AgentCall": "Aria-W3villa-AI-Agent-call",
    "school-teacherWeb": "school-teacher",
    "school-teacherCall": "school-teacher",
    "e-commerceWeb": "e-commerce",
    "e-commerceCall": "e-commerce",
    "pizzaHomeWeb": "pizza-home-web",
    "pizzaHomeCall": "pizza-home-call",
    "TranslatorWeb":"Translator",
}

export const languageCodes = {
    "English (United States)": "en-US",
    "Spanish (Mexico)": "es-MX",
    "Chinese (Simplified)": "zh-CN",
    "Hindi": "hi-IN",
    "Arabic (World)": "ar-001",
    "Portuguese (Brazil)": "pt-BR",
    "Russian": "ru-RU",
    "French (France)": "fr-FR",
    "Bengali": "bn-IN",
    "Indonesian": "id-ID",
    "Japanese": "ja-JP",
    "German": "de-DE",
    "Spanish (Spain)": "es-ES",
    "Portuguese (Portugal)": "pt-PT",
    "Korean": "ko-KR",
    "Vietnamese": "vi-VN",
    "French (Canada)": "fr-CA",
    "Italian": "it-IT",
    "Hebrew": "he-IL",
    "English (India)": "en-IN",
    "English (United Kingdom)": "en-GB",
    "Swedish": "sv-SE",
    "Malay": "ms-MY",
    "Bulgarian": "bg-BG",
    "Finnish": "fi-FI",
    "Dutch (Belgium)": "nl-BE",
    "Romanian": "ro-RO",
    "Thai": "th-TH",
    "Croatian": "hr-HR",
    "Slovak": "sk-SK",
    "Ukrainian": "uk-UA",
    "Chinese (Traditional, Taiwan)": "zh-TW",
    "Greek": "el-GR",
    "English (Ireland)": "en-IE",
    "Catalan": "ca-ES",
    "Norwegian Bokmål": "nb-NO",
    "Danish": "da-DK",
    "Chinese (Traditional, Hong Kong)": "zh-HK",
    "English (Australia)": "en-AU",
    "English (South Africa)": "en-ZA",
    "Hungarian": "hu-HU",
    "Dutch (Netherlands)": "nl-NL",
    "Turkish": "tr-TR",
    "Polish": "pl-PL",
    "Czech": "cs-CZ",
    "Spanish (United States)": "es-US"
};

export const languageNames = [
    "English (United States)",
    "Spanish (Mexico)",
    "Chinese (Simplified)",
    "Hindi",
    "Arabic (World)",
    "Portuguese (Brazil)",
    "Russian",
    "French (France)",
    "Bengali",
    "Indonesian",
    "Japanese",
    "German",
    "Spanish (Spain)",
    "Portuguese (Portugal)",
    "Korean",
    "Vietnamese",
    "French (Canada)",
    "Italian",
    "Hebrew",
    "English (India)",
    "English (United Kingdom)",
    "Swedish",
    "Malay",
    "Bulgarian",
    "Finnish",
    "Dutch (Belgium)",
    "Romanian",
    "Thai",
    "Croatian",
    "Slovak",
    "Ukrainian",
    "Chinese (Traditional, Taiwan)",
    "Greek",
    "English (Ireland)",
    "Catalan",
    "Norwegian Bokmål",
    "Danish",
    "Chinese (Traditional, Hong Kong)",
    "English (Australia)",
    "English (South Africa)",
    "Hungarian",
    "Dutch (Netherlands)",
    "Turkish",
    "Polish",
    "Czech",
    "Spanish (United States)"
];
