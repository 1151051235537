import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import LanguageModal from './LanguageModal'; // Import the LanguageModal component

const LanguageInput = ({ languageSelection, setLanguageSelection, setSessionId, handleVoiceChange }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState("");
    const [isRotating, setIsRotating] = useState(false);

    const languages = ["English", "Hindi", "Korean", "French", "Italian", "Hebrew"];
    const languageVoice = { "English": "Google UK English Female", "Hindi": "Google हिन्दी", "Korean": "Google 한국의", "French": "Google français", "Italian": "Google italiano", "Hebrew": "Carmit" };
    const langCode = {
        "English": "en",
        "Hindi": "hi",
        "Korean": "ko",
        "French": "fr",
        "Italian": "it",
        "Hebrew": "he"
    };

    const handleLanguageSelect = (lang) => {
        setLanguageSelection((prevState) => ({
            ...prevState,
            [modalType]: lang,
        }));
        setSessionId(null);
        if (modalType === "to") {
            handleVoiceChange(languageVoice[lang]);
        }
        setIsModalOpen(false);
    };

    const swapLanguages = () => {
        setIsRotating(true);

        setLanguageSelection((prevState) => ({
            from: prevState.to,
            to: prevState.from
        }));

        setSessionId(null);

        setTimeout(() => {
            setIsRotating(false);
        }, 500);
    };

    return (
        <div className="language-input-container">
            <div className="language-input-wrap">
                {/* <button
                    className="langIcon"
                    type="text"
                    value={languageSelection.from}
                    readOnly
                    onClick={() => {
                        setModalType("from");
                        setIsModalOpen(true);
                    }}
                /> */}
                < button className="langIcon" onClick={() => {
                        setModalType("from");
                        setIsModalOpen(true);
                    }} >
                        <span className="dNone-sm">{languageSelection.from}</span>
                        <span className="showMobile">{langCode[languageSelection.from]}</span>
                </button>
                <FontAwesomeIcon
                    icon={faExchangeAlt}
                    className={`exchange-icon ${isRotating ? 'rotate' : ''}`}
                    onClick={swapLanguages}
                    style={{ cursor: "pointer" }}
                />
                {/* <button
                    className="langIcon"
                    type="text"
                    value={languageSelection.to}
                    readOnly
                    onClick={() => {
                        setModalType("to");
                        setIsModalOpen(true);
                    }}
                /> */}
                < button className="langIcon" onClick={() => {
                        setModalType("to");
                        setIsModalOpen(true);
                    }} >
                        <span className="dNone-sm">{languageSelection.to}</span>
                        <span className="showMobile">{langCode[languageSelection.to]}</span>
                </button>
            </div>

            <LanguageModal
                isModalOpen={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                languages={languages}
                handleLanguageSelect={handleLanguageSelect}
            />
        </div>
    );
};

export default LanguageInput;
